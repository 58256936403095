import React, { useMemo, useState, useEffect, useRef } from "react";
import Masonry from "masonry-layout";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import ThemeContext from "./ThemeContext";
import MatchCard from "./MatchCard";
import ChallengeCard from "./ChallengeCard";
import WinnerTakesAllChallengeCard from "./WinnerTakesAllChallengeCard";

const MatchesAndChallengesSection = ({sectionHeader, showGive, primaryColor, allowChallenges, allowMatches, userChallenge, userMatch, offerChallengeUrl, offerMatchUrl, projectId, tieredCampaignId}) => {
  const [matchallenges, setMatchallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const gridRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const themeContextValue = useMemo(() => ({ primaryColor }), [primaryColor]);

  const fetchMatchallenges = async (page = 1) => {
    setLoading(true);
    const response = await fetch(`/campaigns/${projectId}/matches_and_challenges?page=${page}&tc=${tieredCampaignId}`);
    const data = await response.json();
    setMatchallenges((prevMatchallenges) => [...prevMatchallenges, ...data.data]);
    setCurrentPage(data.current_page);
    setTotalPages(data.total_pages);
    setLoading(false);
    setLoadingMore(false);
  };

  useEffect(() => {
    fetchMatchallenges();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (matchallenges.length > 0 && window.innerWidth > 768) {
      const msnry = new Masonry(gridRef.current, {
        itemSelector: ".matchallenge-item",
        columnWidth: ".matchallenge-item",
        percentPosition: true
      });

      // Clean up Masonry instance on component unmount
      return () => msnry.destroy();
    }
  }, [matchallenges]);

  const loadMore = () => {
    if (currentPage < totalPages) {
      scrollPositionRef.current = window.scrollY;
      setLoadingMore(true);
      fetchMatchallenges(currentPage + 1);
    }
  };

  // Add effect to handle scroll restoration
  useEffect(() => {
    if (scrollPositionRef.current > 0 && !loadingMore) {
      window.scrollTo(0, scrollPositionRef.current);
      scrollPositionRef.current = 0;
    }
  }, [matchallenges, loadingMore]);

  const renderCard = (matchallenge) => {
    if (matchallenge.type === "Challenge" && matchallenge.winner_takes_all) {
      return <WinnerTakesAllChallengeCard challenge={matchallenge} />;
    } else if (matchallenge.type === "Challenge") {
      return <ChallengeCard challenge={matchallenge} />;
    } else {
      return <MatchCard match={matchallenge} />;
    }
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <div id="campaign-matchallenges-section" className="campaign-section grid-standard--third">
        <div className="gap"/>
        <div className="box">
          <div aria-level="2" className="box-header flex flex-col sm:flex-row gap-4" role="heading">
            <h2 className="box-header--icon header-2">{ sectionHeader }</h2>
            <div className="flex flex-col sm:flex-row items-center gap-4">
              {showGive && allowMatches && !userMatch && (
                <a href={offerMatchUrl} className="btn btn-primary w-auto">
                  Offer a Matching Gift
                </a>
              )}
              {showGive && allowChallenges && !userChallenge && (
                <a href={offerChallengeUrl} className="btn btn-primary w-auto">
                  Offer a Challenge Gift
                </a>
              )}
            </div>
          </div>
          {loading && matchallenges.length === 0 ? (
            <div className="flex justify-center items-center h-64">
              <EllipsisHorizontalIcon className="w-12 h-12 text-gray-500 animate-ping" role="img" aria-label="loading icon"/>
            </div>
          ) : (
            <>
              <div ref={gridRef} className="box-content bg-gray-100 matchallenge-grid grid grid-cols-1 sm:grid-cols-4 gap-4 p-4 md:pl-8">
                {matchallenges.map((matchallenge) => (
                  <div className="matchallenge-item" key={matchallenge.id}>
                    {renderCard(matchallenge)}
                  </div>
                ))}
              </div>

              {currentPage < totalPages && (
                <div className="flex justify-center bg-gray-100 p-4">
                  <button type="button" onClick={loadMore} className="btn btn-secondary">
                    {loadingMore ? (
                      <EllipsisHorizontalIcon className="w-5 h-5 text-gray-500 animate-ping" role="img" aria-label="Loading icon" />
                    ) : (
                      "Load more..."
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

export default MatchesAndChallengesSection;
